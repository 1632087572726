<template>
  <div class="table-filter-wrapper">
    <div class="title-row">
      <div class="left-side">
        <div
          v-if="title"
          class="title"
        >
          {{ title }}
        </div>
        <div
          v-if="enableSearch"
          class="search-bar"
        >
          <a-input-search
            v-model:value="searchInput"
            placeholder="Search by keyword"
            style="width: 250px"
            class="search-input"
            @change="onInputFilterChange"
          />
        </div>
      </div>
      <div
        v-if="filterOption.key"
        id="filter-select"
        class="right-side"
      >
        <span class="filter-title">
          {{ filterOption.title }}
        </span>
        <a-select
          v-model:value="selectedValue"
          :getPopupContainer="(trigger) => trigger.parentNode"
          @change="onInputFilterChange"
        >
          <a-select-option
            v-for="opt in filterOption.filterValueList"
            :key="opt.value"
            :value="opt.value"
          >
            {{ opt.title }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <TableSetting
      :tableData="filteredData"
      :isRowSelection="isRowSelection"
      :rowKey="rowKey"
      :isTimeFromNow="isTimeFromNow"
      :isTypeTag="isTypeTag"
      :actions="actions"
      :pagination="filteredPagination"
      :columns_props="columns_props"
      @toggleAction="onToggleAction"
      @rowSelectChange="rowSelectChange"
      @change="onChange"
    />
  </div>
</template>

<script>
import { toRefs, ref, computed } from 'vue';
import TableSetting from '@/components/Table/TableSetting.vue';

export default {
  components: {
    TableSetting,
  },
  props: {
    // this comp setting
    title: String,
    enableSearch: {
      type: Boolean,
      default: true,
    },
    filterOption: Object,
    // original setting here
    rowKey: String,
    isRowSelection: Boolean,
    isActionDropDown: Boolean,
    isTypeTag: Boolean,
    isTimeFromNow: Boolean,
    isTotalActive: Boolean,
    actions: Array,
    columns_props: Object,
    pagination: Object,
    tableData: Array,
  },
  emits: ['toggleAction', 'rowSelectChange', 'change', 'changePage'],
  setup(props, { emit }) {
    const searchInput = ref('');
    const selectedValue = ref('');

    const { tableData, enableSearch, filterOption, pagination } = toRefs(props);

    if (filterOption.value && filterOption.value.defaultValue) {
      selectedValue.value = filterOption.value.defaultValue;
    }

    const filteredData = computed(() => {
      // console.log(
      //   'FD TRIGG',
      //   tableData.value.filter((v) => v.count && v.count > 0)
      // );

      let filtered = [];
      if (tableData.value) {
        filtered = tableData.value;
        if (filterOption.value && selectedValue.value) {
          filtered = filtered.filter((obj) => {
            return obj[filterOption.value.key] === selectedValue.value;
          });
          if (enableSearch.value) {
            let textInput = searchInput.value.trim();
            if (textInput) {
              filtered = filtered.filter((obj) => {
                if (textInput && obj.keyword.indexOf(textInput) > -1) {
                  return true;
                }
                return false;
              });
            }
          }
        } else {
          if (enableSearch.value) {
            let textInput = searchInput.value.trim();
            if (textInput) {
              filtered = filtered.filter((obj) => {
                if (textInput && obj.keyword.indexOf(textInput) > -1) {
                  return true;
                }
                return false;
              });
            }
          }
        }
        return filtered;
      }
      return [];
    });

    const filteredPagination = computed(() => {
      if (filteredData.value) {
        return {
          ...pagination.value,
          total: filteredData.value.length,
        };
      }
      return pagination.value;
    });

    const onToggleAction = (obj) => {
      emit('toggleAction', obj);
    };

    const rowSelectChange = (arr) => {
      emit('rowSelectChange', arr);
    };

    const onChange = (e) => {
      emit('change', e);
      emit('changePage', e);
    };

    const onInputFilterChange = () => {
      emit('change', true);
    };

    return {
      searchInput,
      selectedValue,
      filteredData,
      filteredPagination,
      onToggleAction,
      rowSelectChange,
      onChange,
      onInputFilterChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.table-filter-wrapper {
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .left-side {
      display: flex;
      align-items: center;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #272b41;
        margin-right: 18px;
      }
      .search-bar {
        .search-input {
          border-radius: 18px;
          height: 36px;
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      .filter-title {
        margin-right: 8px;
        color: #5a5f7d;
      }
    }
  }
}
@media screen and (max-width: 688px) {
  .table-filter-wrapper {
    .title-row {
      .left-side {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
